import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Dropdown from "./Dropdown";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import us_eng from "../context/translations/us_eng.json";
import uk_eng from "../context/translations/uk_eng.json";
import us_spa from "../context/translations/us_spa.json";
import es_esp from "../context/translations/es_esp.json";
import de_deu from "../context/translations/de_deu.json";
import pl_pol from "../context/translations/pl_pol.json";

import us_flag from "../images/flags/united-states-of-america.png";
import uk_flag from "../images/flags/united-kingdom.png";
import es_flag from "../images/flags/spain.png";
import de_flag from "../images/flags/germany.png";
import pl_flag from "../images/flags/republic-of-poland.png";

import { useOptimmizeContextController, setLanguage } from "../context";
import "./TopBar.css";

import { makeStyles } from "@material-ui/core/styles";

const Header = ({ white = false, activeLink, weekly = false }) => {
  const [controller, dispatch] = useOptimmizeContextController();
  const { language } = controller;
  // let link_text_id = white ? 'white-link-text' : 'link-text';
  let link_text_id = "link-text";
  const [visible, setVisible] = useState(true);

  const checkActiveItem = (link) => {
    if (link === activeLink) {
      return "link-item-active";
    }
    return "link-item";
  };

  const checkActiveLink = (link) => {
    if (link === activeLink) {
      return "link-text-active";
    }
    return link_text_id;
  };

  const checkActiveWeekLink = (link) => {
    if (link === activeLink) {
      return "weekly-link-active";
    }
    return "weekly-link";
  };

  const supportedLanguages = [
    {
      langName: "English (US)",
      langFile: us_eng,
      utm_code: "us_eng",
      flag: us_flag,
    },
    {
      langName: "English (UK)",
      langFile: uk_eng,
      utm_code: "uk_eng",
      flag: uk_flag,
    },
    {
      langName: "US Spanish",
      langFile: us_spa,
      utm_code: "us_spa",
      flag: us_flag,
    },
    {
      langName: "Spanish",
      langFile: es_esp,
      utm_code: "es_esp",
      flag: es_flag,
    },
    {
      langName: "German",
      langFile: de_deu,
      utm_code: "de_deu",
      flag: de_flag,
    },
    {
      langName: "Polish",
      langFile: pl_pol,
      utm_code: "pl_pol",
      flag: pl_flag,
    },
  ];

  const getFlag = () => {
    for (let lang of supportedLanguages) {
      if (lang.utm_code === language.utm_code) {
        return <img id="flags" src={lang.flag} alt="flags" />;
      }
    }
  };

  const getTopDisplay = () => {
    if (visible) {
      return (
        <React.Fragment>
          <Grid id="weekly-header-links" container>
            <div id="hamburger">
              <MenuOpenIcon onClick={() => setVisible(false)} />
            </div>

            <Grid item id="link-padding" xl={2} md={2} xs={12}>
              <Link to="/week0" id={checkActiveWeekLink("/day1")}>
                {language.week0_navlink}
              </Link>
            </Grid>
            <Grid item id="link-padding" md={3} xs={12}>
              <Link to="/week12more" id={checkActiveWeekLink("/week12")}>
                {language.week12_navlink}
              </Link>
            </Grid>
            <Grid item id="link-padding" md={3} xs={12}>
              <Link to="/week24more" id={checkActiveWeekLink("/week26")}>
                {language.week24_navlink}
              </Link>
            </Grid>
            <Grid item id="link-padding" xl={2} md={2} xs={12}>
              <Link to="/week216" id={checkActiveWeekLink("/followup")}>
                {language.week216_navlink}
              </Link>
            </Grid>
            <Grid item id="link-padding" xl={2} md={2} xs={12}>
              <Link to="/followup" id={checkActiveWeekLink("/followup")}>
                {language.follow_up_navlink}
              </Link>
            </Grid>
          </Grid>

          {/* <Grid id="weekly-header-links" container>
            <Grid item id="link-padding-first" xl={5} md={5} xs={12}>
              <Link to="/followup1" id={checkActiveWeekLink('/followup1')}>
                Follow-Up (Without PSMA-PET progression; PSA level {'<'} 0.2
                ng/mL)
              </Link>
            </Grid>
            <Grid item id="link-padding" xl={4} md={4} xs={12}>
              <Link to="/followup2" id={checkActiveWeekLink('/followup2')}>
                Follow-Up (Without PSMA-PET progression; PSA level ≥ 0.2 ng/mL)
              </Link>
            </Grid>
            <Grid item id="link-padding-first" xl={3} md={3} xs={12}>
              <Link to="/followup3" id={checkActiveWeekLink('/followup3')}>
                Follow-Up (PSMA-PET progression)
              </Link>
            </Grid>
          </Grid> */}
        </React.Fragment>
      );
    } else {
      return (
        <Grid id="weekly-header-links" container>
          <div id="hamburger">
            <MenuIcon onClick={() => setVisible(true)} />
          </div>
        </Grid>
      );
    }
  };

  return (
    <div>
      {weekly && getTopDisplay()}
      <Grid id="header-links" container>
        {/* <Grid item id="link-item-padding" md={1} xs={12}></Grid> */}
        <Grid item id={checkActiveItem("/")} md={1} xs={12}>
          {language.country_code === "POL" ? (
            <Link
              id={checkActiveLink("/")}
              to="/"
              style={{
                fontFamily: ["POL"].includes(language.country_code)
                  ? "Gothiks-Round"
                  : "",
                letterSpacing: ["POL"].includes(language.country_code)
                  ? ".1rem"
                  : "",
              }}
            >
              {language.home_navlink_p1} <br />{" "}
              <div
                style={{
                  fontSize: "1rem",
                  fontFamily: ["POL"].includes(language.country_code)
                    ? "Gothiks-Round"
                    : "",
                  letterSpacing: ["POL"].includes(language.country_code)
                    ? "1px"
                    : "",
                }}
              >
                {language.home_navlink_p2}
              </div>
            </Link>
          ) : (
            <Link id={checkActiveLink("/")} to="/">
              {language.home_navlink}
            </Link>
          )}
        </Grid>
        <Grid item id={checkActiveItem("/overview")} md={3} xs={12}>
          <Link
            id={checkActiveLink("/overview")}
            to="/overview"
            style={{
              fontFamily: ["POL"].includes(language.country_code)
                ? "Gothiks-Round"
                : "",
              letterSpacing: ["POL"].includes(language.country_code)
                ? ".1rem"
                : "",
            }}
          >
            {language.study_overview_navlink}
          </Link>
        </Grid>
        <Grid item id={checkActiveItem("/visits")} md={4} xs={12}>
          <Link
            id={checkActiveLink("/visits")}
            to="/visits"
            style={{
              fontFamily: ["POL"].includes(language.country_code)
                ? "Gothiks-Round"
                : "",
              letterSpacing: ["POL"].includes(language.country_code)
                ? ".1rem"
                : "",
            }}
          >
            {language.study_visits_navlink}
          </Link>
          {/* <Dropdown
            title="Study Visit Health Exams and Tests"
            titlePage={'/visits'}
            id={checkActiveLink('/visits')}
            items={[
              {
                name: 'Study Treatment Phase',
                link: '/studytreatment',
              },
              { name: 'Follow-Up Phase', link: '/followup' },
            ]}
          /> */}
        </Grid>
        <Grid item id={checkActiveItem("/contact")} md={3} xs={12}>
          <Link
            id={checkActiveLink("/contact")}
            to="/contact"
            style={{
              fontFamily: ["POL"].includes(language.country_code)
                ? "Gothiks-Round"
                : "",
              letterSpacing: ["POL"].includes(language.country_code)
                ? ".1rem"
                : "",
            }}
          >
            {language.contact_navlink}
          </Link>
        </Grid>
        <Grid item id="link-item-padding" sx={{ padding: 2 }} md={1} xs={12}>
          <div
            className="container"
            style={{ padding: 15, fontFamily: "Abingdon Regular" }}
          >
            <div className="d-flex justify-content-center">
              <div className="dropdown">
                <button
                  className="btn btn-link dropdown-toggle hover-color"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {getFlag()}
                  <span>{language.name}</span>
                </button>
                <ul
                  className="dropdown-menu ul-dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {supportedLanguages.map((lang) => (
                    <li
                      className="dropdown-list"
                      key={lang.utm_code}
                      style={{
                        opacity: lang.utm_code === lang.utm_code ? "0.5" : "1",
                      }}
                    >
                      <button
                        className="dropdown-item btn-sm"
                        onClick={() => {
                          setLanguage(dispatch, lang.langFile);
                        }}
                      >
                        <img id="flags" alt="flags" src={lang.flag} />
                        {lang.langName}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Grid>

        {/* <Grid item id="link-item-padding" xl={3} sm={3} xs={12}></Grid>
        <Grid item id={checkActiveItem("/schedule")} xl={4} sm={4} xs={12}>
          <Dropdown
            title=" Clinic Visit Health Exams and Tests"
            titlePage={"/schedule"}
            id={checkActiveLink("/schedule")}
            items={[
              { name: "Day 1 Visit", link: "/day1" },
              { name: "End of Week 12 Visit", link: "/week12" },
              { name: "End of Week 26 Visit", link: "/week26" },
              { name: "Follow-Up Phases", link: "/follow_ups" },
              {
                name:
                  "Follow-Up (Without PSMA-PET progression; PSA level < 0.2 ng/mL)",
                link: "/followup1",
              },
              {
                name:
                  "Follow-Up (Without PSMA-PET progression; PSA level ≥ 0.2 ng/mL)",
                link: "/followup2",
              },
              { name: "Follow-Up (PSMA-PET progression)", link: "/followup3" },
            ]}
          />
        </Grid>
        <Grid item id={checkActiveItem("/contact")} xl={3} sm={3} xs={12}>
          <Link id={checkActiveLink("/contact")} to="/contact">
            Contact Information
          </Link>
        </Grid>
        <Grid item xl={3} md={3} xs={12}></Grid> */}
      </Grid>
    </div>
  );
};

export default Header;
