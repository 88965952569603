import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "./Header";
// import visitSideImg from '../images/exams_tests_img.png';
import Accordian from "./Accordian";
import AccordianItalic from "./AccordianItalic";
import icon1 from "../images/week/icon.png";
import icon2 from "../images/week/icon2.png";
import icon3 from "../images/week/icon3.png";
import icon4 from "../images/week/icon4.png";
import icon5 from "../images/week/icon5.png";
import icon6 from "../images/week/icon6.png";
import icon7 from "../images/week/icon7.png";
import icon8 from "../images/week/icon8.png";
import icon9 from "../images/week/icon9.png";
import icon10 from "../images/week/icon10.png";
import icon11 from "../images/week/icon11.png";
import { useOptimmizeContextController } from "../context";

const Week0 = ({ activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.week0_title;
  }, []);

  return (
    <div id="info-page">
      <Header white={true} weekly={true} activeLink={activeLink} />
      <div id="top-divider" />

      <Grid container id="info-container">
        <Grid
          item
          className="purple-border"
          md={12}
          xl={12}
          xs={12}
          style={{ margin: "0 auto" }}
          id="schedule-copy"
        >
          <Typography variant="h2" id="info-header">
            {language.week0_tbox_header}
          </Typography>
          <br />
          <br />
          <Typography style={{ color: "#000", textAlign: "left" }}>
            {language.week0_tbox_text}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ height: "52px" }} />

      <div id="animate-fade-in">
        <Grid container id="icon-container">
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon1} alt={icon1} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i1_title}
                body={language.week0_i1_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon2} alt={icon2} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i2_title}
                body={language.week0_i2_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon3} alt={icon3} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              {language.utm_code === "us_spa" ? (
                <AccordianItalic
                  title={language.week0_i3_title}
                  body1={language.week0_i3_body1}
                  bodyItalic={language.week0_i3_body_italic}
                  body2={language.week0_i3_body2}
                />
              ) : (
                <Accordian
                  title={language.week0_i3_title}
                  body={language.week0_i3_details}
                />
              )}
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon4} alt={icon4} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i4_title}
                body={language.week0_i4_details}
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ height: "52px" }} />
        <Grid container id="icon-container">
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon5} alt={icon5} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i5_title}
                body={language.week0_i5_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon6} alt={icon6} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i6_title}
                body={language.week0_i6_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon7} alt={icon7} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i7_title}
                body={language.week0_i7_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon8} alt={icon8} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i8_title}
                body={language.week0_i8_details}
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ height: "52px" }} />
        <Grid container id="icon-container">
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon9} alt={icon9} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i9_title}
                body={language.week0_i9_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon10} alt={icon10} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i10_title}
                body={language.week0_i10_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon11} alt={icon11} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.week0_i11_title}
                body={language.week0_i11_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid"></Grid>
        </Grid>
      </div>

      <div style={{ height: "52px" }} />
    </div>
  );
};

export default Week0;
