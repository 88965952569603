import React, { useEffect, useState } from "react";
import { Modal, Card, CardContent } from "@material-ui/core";
import studymedication_side_img from "../images/ios-share.png";

import { useOptimmizeContextController } from "../context";

const PWAInstallPrompt = () => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  const [installPrompt, setInstallPrompt] = useState();
  const [iosInstallPrompt, setIosInstallPrompt] = useState(false);
  const [webInstallPrompt, setWebInstallPrompt] = useState(false);

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      event.preventDefault();

      // // check if user has already been asked
      // if (userShouldBePromptedToInstall) {
      //   // store the event for later use
      //   setInstallPromptEvent(event);
      // }
      setInstallPrompt(event);
    };
    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    return () =>
      window.removeEventListener(
        "beforeinstallprompt",
        beforeInstallPromptHandler
      );
  }, [webInstallPrompt]);

  useEffect(() => {
    const webDeclined = localStorage.getItem("webInstallDeclined");
    const webAccepted = localStorage.getItem("webInstallAccepted");
    if (webDeclined) {
      return;
    } else if (webAccepted) {
      return;
    } else {
      const checkForWebInstall = localStorage.getItem("webInstallPromptedat");

      if (checkForWebInstall === null || checkForWebInstall === undefined) {
        const today = new Date();
        localStorage.setItem("webInstallPromptedat", today);
      }

      const then = new Date(checkForWebInstall);
      const now = new Date();

      const msBetweenDates = Math.abs(then.getTime() - now.getTime());

      const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

      if (daysBetweenDates < 30) {
        setWebInstallPrompt(false);
      } else {
        setWebInstallPrompt(true);
      }
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    if (navigator.standalone) {
      //user has already installed the app
      setIosInstallPrompt(false);
    }
    const iosDeclined = localStorage.getItem("iosInstallDeclined");
    const iosAccepted = localStorage.getItem("iosInstallAccepted");

    const checkIosInstall = localStorage.getItem("iosInstallPromptedat");

    if (checkIosInstall === null || checkIosInstall === undefined) {
      const today = new Date();
      localStorage.setItem("iosInstallPromtedat", today);
    }

    if (iosDeclined) {
      return;
    } else if (iosAccepted) {
      return;
    } else {
      const then = new Date(checkIosInstall);
      const now = new Date();

      const msBetweenDates = Math.abs(then.getTime() - now.getTime());

      const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);

      if (daysBetweenDates < 30) {
        setIosInstallPrompt(false);
      } else {
        const ua = window.navigator.userAgent;
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i);
        setIosInstallPrompt(isIPad || isIPhone);
      }
    }
  }, []);

  const handleInstallAccepted = () => {
    // show native prompt
    installPrompt.prompt();

    // decide what to do after the user chooses
    installPrompt.userChoice.then((choice) => {
      // if the user declined, we don't want to show the prompt again
      if (choice.outcome !== "accepted") {
        // handleUserSeeingInstallPrompt();
        localStorage.setItem("webInstallAccepted", true);
      }
      setInstallPrompt(null);
    });
  };

  const handleWebInstallDeclined = () => {
    setWebInstallPrompt(false);
    setIosInstallPrompt(false);
    localStorage.setItem("webInstallDeclined", true);
  };

  const handleIosInstallDeclined = () => {
    setWebInstallPrompt(false);
    setIosInstallPrompt(false);
    localStorage.setItem("iosInstallDeclined", true);
  };

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }

  return (
    <Modal open={true}>
      <Card>
        <CardContent>
          {iosInstallPrompt && (
            <div style={{ textAlign: "center" }}>
              <h1>{language.ios_install_header}</h1>
              <h2 style={{ marginTop: "16px", fontSize: "1em" }}>
                {language.iso_install_line1}
                {
                  <img
                    src={studymedication_side_img}
                    style={{ margin: "auto 8px 0px 8px" }}
                    className=""
                    alt="Add to homescreen"
                    width="20"
                  />
                }
                {language.ios_install_line2}
              </h2>
              <div>
                <button
                  onClick={() => {
                    handleIosInstallDeclined();
                  }}
                  style={{ width: "40%" }}
                >
                  {language.ios_install_close}
                </button>
              </div>
            </div>
          )}
          {webInstallPrompt && !iosInstallPrompt && (
            <div style={{ textAlign: "center" }}>
              <h1>{language.web_install_header}</h1>
              <button
                onClick={() => {
                  handleInstallAccepted();
                }}
                style={{ width: "40%", margin: "0" }}
              >
                {language.web_install_install}
              </button>
              <button
                onClick={() => {
                  handleWebInstallDeclined();
                }}
                style={{ width: "40%", margin: "0", marginLeft: "8%" }}
              >
                {language.web_install_close}
              </button>
            </div>
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default PWAInstallPrompt;
