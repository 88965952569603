import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
// import Footer from './Footer';
// import welcome_side_img_disclaimer from '../images/welcome_side_img_disclaimer.png';
import contactImg from '../images/contact_img.png';
import { useOptimmizeContextController } from "../context";

const ContactPage = ({ activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.contact_title;
  }, []);

  return (
    <div id="info-page">
      <Header activeLink={activeLink} />
      <div id="top-divider" />

      <Grid container id="info-container">
        <Grid
          item
          className="purple-border"
          md={12}
          xl={12}
          xs={12}
          style={{ margin: '0 auto' }}
          id="schedule-copy"
        >
          <Typography
            variant="h2"
            id="info-header"
            style={{ color: '#52bfa2' }}
          >
            {language.contact_header}
          </Typography>
        </Grid>
      </Grid>

      <Grid container id="info-container">
        <Grid
          item
          md={1}
          xs={12}
          display={{ xs: 'none', sm: 'none', md: 'block' }}
        ></Grid>
        <Grid
          item
          className="contact-pg-text"
          md={7}
          xl={7}
          xs={12}
          style={{
            margin: 'auto',
            position: 'relative',
            zIndex: '10',
          }}
          id="info-copy"
        >
          <br />
          <div className="purple-box">
            <Typography style={{ color: '#000' }}>
              <span className="mint-text">
                {language.contact_box_line1_p1}
              </span>
              {language.contact_box_line1_p2}
              <br />
              <br />
              <span className="mint-text">
              {language.contact_box_line2_p1}
              </span>
              {language.contact_box_line2_p2}
              <br />
              <br />
              <span className="mint-text">
              {language.contact_box_line3_p1}
              </span>
              {language.contact_box_line3_p2}
            </Typography>
          </div>
        </Grid>
        <Grid item md={4} xs={12} className="animate-wrapper">
          <div id="animate-right-in" className="contact-pg-side-img">
            <img src={contactImg} alt={contactImg} id="side-img-3" />
          </div>
        </Grid>
      </Grid>
      <div style={{ height: '52px' }} />
      <div id="cover-logo-prim"></div>
    </div>
  );
};

export default ContactPage;
