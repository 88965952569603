import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "./Header";
import optimmize2_logo from "../images/optimmize2_logo.png";
import welcome1 from "../images/welcome_img1.png";
import welcome2 from "../images/welcome_img2.png";
import welcome3 from "../images/welcome_img3.png";

import { useOptimmizeContextController } from "../context";

const CoverPage = ({ header_image, activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.home_title;
  }, []);

  return (
    <div id="cover-page">
      <Header activeLink={activeLink} />
      <div id="top-divider" />
      <div style={{ height: "52px" }} />
      <Grid container id="info-container">
        <Grid item md={2}></Grid>
        <Grid
          item
          md={8}
          xs={12}
          style={{
            margin: "0 auto",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div className="purple-border">
            <Typography variant="h1">
              {language.home_header1} <br />
              {language.utm_code === "us_spa" && (
                <>
                  {language.home_header1_2} <br />
                </>
              )}
              {language.home_header2}
            </Typography>
            <br />
            <br />
            <Typography variant="h3" style={{ fontWeight: "700" }}>
              {language.home_header3}
              <br />
              {language.home_header4}
            </Typography>
            <br />
            <img
              id="optimmize2_logo"
              src={optimmize2_logo}
              alt={optimmize2_logo}
            />
          </div>
        </Grid>
        <Grid item md={2}></Grid>
        {/* <Grid item md={5} xl={5} xs={12}>
          <div id="animate-right-in" className="contact-pg-side-img">
            <img src={header_image} alt={header_image} id="side-img-3" />
          </div>
        </Grid> */}
      </Grid>

      <Grid container>
        <Grid item md={2} xs={12}></Grid>
        <Grid item md={8} xs={12} style={{ padding: "0 8%", marginTop: "-5%" }}>
          <div id={header_image} />
        </Grid>
        <Grid item md={2} xs={12}></Grid>
      </Grid>

      <Grid container>
        <Grid item md={2} xs={12}></Grid>
        <Grid item md={8} xs={12}>
          <div className="welcome_container_right">
            <img className="welcome_img" src={welcome1} alt={welcome1} />
            <div className="welcome1_text">
              <h2>{language.home_box1}</h2>
            </div>
          </div>
        </Grid>
        <Grid item md={2} xs={12}></Grid>
      </Grid>
      <div style={{ height: "52px" }} />

      <Grid container>
        <Grid item md={2} xs={12}></Grid>
        <Grid item md={8} xs={12}>
          <div className="welcome_container_left">
            <img className="welcome_img" src={welcome2} alt={welcome2} />
            <div className="welcome2_text">
              {language.home_box2_line1} <br />
              <br />
              {language.home_box2_line2}
            </div>
          </div>
        </Grid>
        <Grid item md={2} xs={12}></Grid>
      </Grid>
      <div style={{ height: "52px" }} />

      <Grid container>
        <Grid item md={2} xs={12}></Grid>
        <Grid item md={8} xs={12}>
          <div className="welcome_container_right">
            <img className="welcome_img" src={welcome3} alt={welcome3} />
            <div
              className="welcome3_text"
              style={{
                top: ["POL"].includes(language.country_code) ? "22%" : "28%",
              }}
            >
              {language.home_box3_line1} <br />
              <br />
              {language.home_box3_line2}
              <br />
              <h3
                className={
                  ["POL", "DEU"].includes(language.country_code) ? "polish" : ""
                }
              >
                {language.home_box3_attribute}
              </h3>
            </div>
          </div>
        </Grid>
        <Grid item md={2} xs={12}></Grid>
      </Grid>
      <div style={{ height: "100px" }} />
      {/* <div id="header-image" >
        <div id={header_image} />
      </div> */}
      {/* <Grid container>
        <Grid item md={12} xl={12} xs={12} style={{ textAlign: 'center' }}>
          <Typography variant="h2" id="cover-text">
            Digital Patient Resource Guide –{' '}
            <span style={{ fontWeight: '200' }}>Interventional Group</span>
          </Typography>
        </Grid>
        <Grid item md={12} xl={12} xs={12} style={{ textAlign: 'center' }}>
          <div id="animate-fade-in" style={{ maxWidth: '70%', margin: 'auto' }}>
            <Typography variant="h4" id="cover-sub-text">
              <br />
              Protocol 56021927PCR3015
              <br />
              <br />
              Please <Link to="/welcome">click here</Link> to visit the Welcome
              Page, or you may click any of the sections above to go directly to
              that section.
            </Typography>
          </div>
          <div id="animate-fade-in">
            <div id="cover-logo-prim">
              <img src={primordium_logo} id="cover-logo-img" />
            </div>
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default CoverPage;
