import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const Accordion = withStyles({
  root: {
    background: "#7151A1",
    borderRadius: "10px",
    maxWidth: "75%",
    boxShadow: "none",
    margin: "auto",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export default function Accordian({ title, body }) {
  const [expanded, setExpanded] = React.useState("false");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div style={{ margin: "8px" }}>
      <Accordion
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            <b style={{ color: "#fff", fontWeight: "500", fontSize: "2em" }}>
              +{"  "}
            </b>
          </Typography>
          <Typography
            style={{
              margin: "auto",
              paddingBottom: "0",
              paddingLeft: ".3rem",
              textAlign: "left",
            }}
          >
            {typeof title === "string" && <b>{title}</b>}
            {typeof title === "object" && title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ textAlign: "left" }}>{body}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
