import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "./Header";
// import visitSideImg from '../images/exams_tests_img.png';
import Accordian from "./Accordian";
import icon1 from "../images/week/icon.png";
import icon2 from "../images/week/icon2.png";
import icon3 from "../images/week/icon3.png";
import icon4 from "../images/week/icon4.png";
import icon5 from "../images/week/icon5.png";
import icon6 from "../images/week/icon6.png";
import icon7 from "../images/week/icon7.png";
import icon8 from "../images/week/icon8.png";
import icon9 from "../images/week/icon9.png";
import icon10 from "../images/week/icon10.png";
import icon11 from "../images/week/icon11.png";
// import callOutImg from '../images/callout_img.png';
// import icon8 from '../images/week/icon8.png';
// import icon9 from '../images/week/icon9.png';
import { useOptimmizeContextController } from "../context";

const FollowUp = ({ activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.follow_up_title;
  }, []);

  return (
    <div id="info-page">
      <Header white={true} weekly={true} activeLink={activeLink} />
      <div id="top-divider" />

      <Grid container id="info-container">
        <Grid
          item
          className="purple-border"
          md={12}
          xl={12}
          xs={12}
          style={{ margin: "0 auto" }}
          id="schedule-copy"
        >
          <Typography variant="h2" id="info-header">
            {language.follow_up_tbox_header}
          </Typography>
          <br />
          <br />
          <Typography style={{ color: "#000", textAlign: "left" }}>
            {language.follow_up_tbox_text}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ height: "52px" }} />

      <div id="animate-fade-in">
        <Grid container id="icon-container" sx={{ height: "50rem" }}>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon1} alt={icon1} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.follow_up_i1_title}
                body={language.follow_up_i1_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon2} alt={icon2} id="link-icon" />
            <div style={{ marginTop: "16px" }}>
              <Accordian
                title={language.follow_up_i2_title}
                body={language.follow_up_i2_details}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div style={{ height: "52px" }} />
    </div>
  );
};

export default FollowUp;
