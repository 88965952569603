// react imports
import React, { useEffect } from "react";
import { createContext, useContext, useReducer, useMemo } from "react";

// translation imports
import us_eng from "./translations/us_eng.json";
import uk_eng from "./translations/uk_eng.json";
import us_spa from "./translations/us_spa.json";
import es_esp from "./translations/es_esp.json";
import de_deu from "./translations/de_deu.json";
import pl_pol from "./translations/pl_pol.json";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const OptimmizeContext = createContext();

// Setting custom name for the context which is visible on react dev tools
OptimmizeContext.displayName = "OptimmizeContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "LANGUAGE": {
      return { ...state, language: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialLanguage = () => {
  //Set Country Code based on UTM codes, if they are present
  let utm_code = new URLSearchParams(window.location.search).get(
    "utm_campaign"
  );

  const supportedLanguages = {
    us_eng: {
      json: us_eng,
      iso_country_code: "USA",
    },
    uk_eng: {
      json: uk_eng,
      iso_country_code: "GBR",
    },
    us_spa: {
      json: us_spa,
      iso_country_code: "USA",
    },
    es_esp: {
      json: es_esp,
      iso_country_code: "ESP",
    },
    de_deu: {
      json: de_deu,
      iso_country_code: "DEU",
    },
    pl_pol: {
      json: pl_pol,
      iso_country_code: "POL",
    },
  };

  if (utm_code in supportedLanguages) {
    let language = supportedLanguages[utm_code].json;
    // console.log(language);
    return language;
  } else {
    return us_eng;
  }
};

// Material Dashboard 2 React context provider
function OptimmizeContextControllerProvider({ children }) {
  const initialState = {
    language: initialLanguage(),
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return (
    <OptimmizeContext.Provider value={value}>
      {children}
    </OptimmizeContext.Provider>
  );
}

// Material Dashboard 2 React custom hook for using context
function useOptimmizeContextController() {
  const context = useContext(OptimmizeContext);

  if (!context) {
    throw new Error(
      "useOptimmizeContextController should be used inside the OptimmizeContextControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
OptimmizeContextControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setLanguage = (dispatch, value) => dispatch({ type: "LANGUAGE", value });

export {
  OptimmizeContextControllerProvider,
  useOptimmizeContextController,
  setLanguage,
};
