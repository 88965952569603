import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";

import { useOptimmizeContextController, setLanguage } from "./context";
import us_eng from "./context/translations/us_eng.json";
import us_spa from "./context/translations/us_spa.json";
import es_esp from "./context/translations/es_esp.json";
import pl_pol from "./context/translations/pl_pol.json";
import de_deu from "./context/translations/de_deu.json";

import CoverPage from "./components/CoverPage";
// import InfoPage from './components/InfoPage';
import VisitsPage from "./components/VisitsPage";
import OverviewPage from "./components/OverviewPage";
import ContactPage from "./components/ContactPage";
// import StudyMedicationPhase from './components/StudyMedicationPhase';
// import ResponsibilitiesPage from './components/ResponsibilitiesPage';
// import NotToDoPage from './components/NotToDoPage';
// import AboutMedicationPage from './components/AboutMedicationPage';
// import AdditionalPage from './components/AdditionalPage';
import Week0 from "./components/Week0";
import Week12More from "./components/Week12More";
import Week24More from "./components/Week24More";
import Week216 from "./components/Week216";
import FollowUpPage from "./components/FollowUpPage";
// import Week8 from './components/Week8';
// import Week10 from './components/Week10';
// import Week12 from './components/Week12';
import PWAInstallPrompt from "./components/PWAInstallPrompt";
// import Footer from './components/Footer';
// import PrivacyPolicy from './components/PrivacyPolicy';
// import CookiePolicy from './components/CookiePolicy';
// import LegalNotice from './components/LegalNotice';
// import { getUserLocale } from 'get-user-locale';
// import LanguageSelect from './components/LanguageSelect';
// import CookieConsent from 'react-cookie-consent';

import { isSafari, isFirefox, isIE } from "react-device-detect";

function App() {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    console.log(language.country_code);
  });
  // const userLocale = getUserLocale();

  // const [lang, setLang] = useState('');
  // const [langName, setLangName] = useState('en-AU');
  // const langs = {
  //   'en-AU': { path: 'AUE', name: 'English AUS' },
  //   'de-AT': { path: 'GEA', name: 'German AT' },
  //   'nl-BE': { path: 'DUB', name: 'Dutch BE' },
  //   'fr-BE': { path: 'FRB', name: 'French BE' },
  //   'cs-CZ': { path: 'CZE', name: 'Czech' },
  //   'da-DK': { path: 'DAN', name: 'Danish' },
  //   'de-DE': { path: 'GER', name: 'German DE' },
  //   'hu-HU': { path: 'HUN', name: 'Hungarian' },
  //   'ar-LB': { path: 'ARL', name: 'Arabic' },
  //   'en-LB': { path: 'ELE', name: 'Lebanon English' },
  //   'fr-LB': { path: 'FLE', name: 'Lebanon French' },
  //   'pl-PL': { path: 'POL', name: 'Polish' },
  //   'ru-RU': { path: 'RUS', name: 'Russian' },
  //   'sv-SE': { path: 'SWE', name: 'Swedish' },
  //   'it-IT': { path: 'ITA', name: 'Italian' },
  // };

  // const initialLangCheck = () => {
  //   // determine if language should be set by url
  //   console.log('initial lang check running');
  //   for (let l of Object.keys(langs)) {
  //     let value = langs[l];
  //     if (window.location.pathname.includes(value.path) && value.path !== '') {
  //       setLang(value.path);
  //       setLangName(l);
  //       console.log('language set to: ' + value.name);
  //       return;
  //     }
  //   }

  //   // if no url set, determine what language to set by user's locale
  //   if (userLocale !== langName) {
  //     for (let l of Object.keys(langs)) {
  //       let value = langs[l];
  //       if (userLocale.includes(l)) {
  //         setLang(value.path);
  //         setLangName(l);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   initialLangCheck();
  // }, []);

  const [langReload, setLangReload] = useState(false);

  // const selectLang = (l) => {
  //   setLang(langs[l].path);
  //   setLangName(l);
  //   setLangReload(true);
  // };

  // const checkLang = () => {
  //   console.log('check lang');
  //   if (langReload) {
  //     setLangReload(false);
  //     window.location.href = `https://qdwebdev.com/PRIMORDIUM/${lang}/`;
  //   }
  // };

  // const [allowCookies, setAllowCookies] = useState(false);

  return (
    <Router>
      {/* <Router basename={`/optimmize-2`}> */}
      {/* <CookieConsent
        location="top"
        buttonText="Accept"
        onAccept={() => {
          setAllowCookies(true);
        }}
        debug={true}
        enableDeclineButton
        declineButtonText="Reject"
        onDecline={() => {
          setAllowCookies(false);
        }}
        buttonStyle={{
          backgroundColor: '#6b944f',
          color: '#FFFFFF',
          fontFamily: 'ff-tisa-web-pro',
        }}
      >
        <p style={{ color: '#FFFFFF' }}>
          We use cookies to allow our site to work properly and analyze our
          traffic. Data may be shared with our analytics partners. Please click
          “Accept” if you agree to our use of cookies or “Reject” if you only
          agree to necessary cookies.
        </p>
      </CookieConsent> */}
      <ScrollToTop />
      {/* {checkLang()} */}
      {/* <LanguageSelect
        lang={langName}
        all_languages={langs}
        setLang={selectLang}
      /> */}
      <Switch>
        {/* <Route path={`/welcome`}>
          <div id="max-width">
            <InfoPage activeLink={'/welcome'} />
          </div>
        </Route>
        <Route path={`/nottodo`}>
          <div id="max-width">
            <NotToDoPage activeLink={'/nottodo'} />
          </div>
        </Route>
        <Route path={`/aboutmedication`}>
          <div id="max-width">
            <AboutMedicationPage activeLink={'/nottodo'} />
          </div>
        </Route>
        <Route path={`/nottodomedication`}>
          <div id="max-width">
            <AdditionalPage activeLink={'/nottodo'} />
          </div>
        </Route>
        <Route path={`/studytreatment`}>
          <div id="max-width">
            <StudyMedicationPhase activeLink={'/studytreatment'} />
          </div>
        </Route>
        <Route path={`/responsibilities`}>
          <div id="max-width">
            <ResponsibilitiesPage activeLink={'/responsibilities'} />
          </div>
        </Route> */}
        <Route path={`/followup`}>
          <div id="max-width">
            <FollowUpPage activeLink={"/visits"} />
          </div>
        </Route>
        <Route path={`/overview`}>
          <div id="max-width">
            <OverviewPage activeLink={"/overview"} />
          </div>
        </Route>
        <Route path={`/contact`}>
          <div id="max-width">
            <ContactPage activeLink={"/contact"} />
          </div>
        </Route>
        <Route path={`/visits`}>
          <div id="max-width">
            <VisitsPage activeLink={"/visits"} />
          </div>
        </Route>
        <Route path={`/week0`}>
          <div id="max-width">
            <Week0 activeLink={"/visits"} />
          </div>
        </Route>
        <Route path={`/week12more`}>
          <div id="max-width">
            <Week12More activeLink={"/visits"} />
          </div>
        </Route>
        <Route path={`/week24more`}>
          <div id="max-width">
            <Week24More activeLink={"/visits"} />
          </div>
        </Route>
        <Route path={`/week216`}>
          <div id="max-width">
            <Week216 activeLink={"/visits"} />
          </div>
        </Route>
        {/* <Route path={`/follow_ups`}>
          <div id="max-width">
            <FollowUpPage activeLink={'/follow_ups'} />
          </div>
        </Route> */}
        {/* <Route path={`/day1`}>
          <div id="max-width">
            <Week0 activeLink={'/day1'} />
          </div>
        </Route>
        <Route path={`/week12`}>
          <div id="max-width">
            <Week2 activeLink={'/week12'} />
          </div>
        </Route>
        <Route path={`/week26`}>
          <div id="max-width">
            <Week4 activeLink={'/week26'} />
          </div>
        </Route>

        <Route path={`/followup1`}>
          <div id="max-width">
            <Week8 activeLink={'/followup1'} />
          </div>
        </Route>
        <Route path={`/followup2`}>
          <div id="max-width">
            <Week10 activeLink={'/followup2'} />
          </div>
        </Route>
        <Route path={`/followup3`}>
          <div id="max-width">
            <Week12 activeLink={'/followup3'} />
          </div>
        </Route>
        <Route path={`/privacy`}>
          <div id="max-width">
            <PrivacyPolicy />
          </div>
        </Route>
        <Route path={`/cookie`}>
          <div id="max-width">
            <CookiePolicy />
          </div>
        </Route>
        <Route path={`/legal`}>
          <div id="max-width">
            <LegalNotice />
          </div>
        </Route> */}
        <Route path={`/`}>
          <div id="max-width">
            <CoverPage header_image="main_header_img" activeLink={"/"} />
          </div>
        </Route>
      </Switch>
      {/* <Footer /> */}
      {isFirefox || isSafari || isIE ? "" : <PWAInstallPrompt />}
    </Router>
  );
}

export default App;
