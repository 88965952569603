import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import Header from "./Header";
import overview_img from "../images/overview_img.png";
import { useOptimmizeContextController } from "../context";

const OverviewPage = ({ activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.overview_title;
  }, []);

  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    console.log({ event, isExpanded });
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <div id="info-page">
      <Header activeLink={activeLink} />
      <div id="top-divider" />

      <Grid container id="info-container">
        <Grid
          item
          className="purple-border"
          md={12}
          xl={12}
          xs={12}
          style={{ margin: "0 auto" }}
          id="schedule-copy"
        >
          <Typography variant="h2" id="info-header">
            {language.overview_header}
          </Typography>
        </Grid>
      </Grid>

      <Grid container id="info-container">
        <Grid item md={8} xs={12} style={{ margin: "auto" }} id="info-copy">
          <div className="purple-box">
            <Typography style={{ color: "#000" }}>
              {language.overview_box1_select}
            </Typography>
            <br />

            <div className="overview-accordion">
              <Accordion
                expanded={expandedPanel === "panel1"}
                onChange={handleAccordionChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="AccordionSummary"
                  style={{
                    fontFamily: ["POL"].includes(language.country_code)
                      ? "Gothiks-Round"
                      : "",
                  }}
                >
                  {language.overview_q1}
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ul>
                      <li>{language.overview_q1_a1}</li>
                      <li>{language.overview_q1_a2}</li>
                      <li>{language.overview_q1_a3}</li>
                      <li>{language.overview_q1_a4}</li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedPanel === "panel2"}
                onChange={handleAccordionChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="AccordionSummary"
                  style={{
                    fontFamily: ["POL"].includes(language.country_code)
                      ? "Gothiks-Round"
                      : "",
                  }}
                >
                  {language.overview_q2}
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ul>
                      <li>{language.overview_q2_a1}</li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedPanel === "panel3"}
                onChange={handleAccordionChange("panel3")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="AccordionSummary"
                  style={{
                    fontFamily: ["POL"].includes(language.country_code)
                      ? "Gothiks-Round"
                      : "",
                  }}
                >
                  {language.overview_q3}
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ul>
                      <li>{language.overview_q3_a1}</li>
                      <li>{language.overview_q3_a2}</li>
                      <li>{language.overview_q3_a3}</li>
                      <li>
                        {language.overview_q3_a4_p1}
                        <Link to="/visits" style={{ color: "#87bc9e" }}>
                          {language.overview_q3_a4_link}
                        </Link>
                        {language.overview_q3_a4_p2}
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedPanel === "panel4"}
                onChange={handleAccordionChange("panel4")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="AccordionSummary"
                  style={{
                    fontFamily: ["POL"].includes(language.country_code)
                      ? "Gothiks-Round"
                      : "",
                  }}
                >
                  {language.overview_q4}
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ul>
                      <li>{language.overview_q4_a1}</li>
                      <li>{language.overview_q4_a2}</li>
                      <li>{language.overview_q4_a3}</li>
                      <li>{language.overview_q4_a4}</li>
                      <li>
                        {language.overview_q4_a5_p1}
                        <ul>
                          <li>{language.overview_q4_a5_p2}</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expandedPanel === "panel5"}
                onChange={handleAccordionChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  className="AccordionSummary"
                  style={{
                    fontFamily: ["POL"].includes(language.country_code)
                      ? "Gothiks-Round"
                      : "",
                  }}
                >
                  {language.overview_q5}
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <ul>
                      <li>{language.overview_q5_a1}</li>
                      <li>{language.overview_q5_a2}</li>
                      <li>{language.overview_q5_a3}</li>
                      <li>{language.overview_q5_a4}</li>
                      <li>{language.overview_q5_a5}</li>
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </Grid>
        <Grid item md={4} xs={12} className="contact-pg-side-img2">
          <div id="animate-right-in">
            <img src={overview_img} alt={overview_img} id="side-img-2" />
          </div>
        </Grid>
        {/* <Grid item md={6} xl={6} sm={12} xs={12} id="button-grid-right">
          <button id="grid-button">
            <Link to="/studytreatment" style={{ textDecoration: "none" }}>
              <div id="button-text">Study Treatment Phase</div>
            </Link>
          </button>
        </Grid>
        <Grid item md={6} xl={6} sm={12} xs={12} id="button-grid-left">
          <button id="grid-button">
            <Link to="/followup" style={{ textDecoration: "none" }}>
              <div id="button-text">Follow-Up Phase</div>
            </Link>
          </button>
        </Grid> */}
      </Grid>
      <div style={{ height: "80px" }} />
    </div>
  );
};

export default OverviewPage;
