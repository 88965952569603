import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Header from './Header';
// import visitSideImg from '../images/exams_tests_img.png';
import Accordian from './Accordian';
import AccordianItalic from './AccordianItalic';
import icon1 from '../images/week/icon.png';
import icon2 from '../images/week/icon2.png';
import icon3 from '../images/week/icon3.png';
import icon4 from '../images/week/icon4.png';
import icon5 from '../images/week/icon5.png';
import icon6 from '../images/week/icon6.png';
import icon7 from '../images/week/icon7.png';
import icon10 from '../images/week/icon10.png';
import icon11 from '../images/week/icon11.png';
import callOutImg from '../images/callout_img.png';
// import icon8 from '../images/week/icon8.png';
// import icon9 from '../images/week/icon9.png';
import { useOptimmizeContextController } from "../context";

const Week12More = ({ activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.week12_title;
  }, []);

  return (
    <div id="info-page">
      <Header white={true} weekly={true} activeLink={activeLink} />
      <div id="top-divider" />

      <Grid container id="info-container">
        <Grid
          item
          className="purple-border"
          md={12}
          xl={12}
          xs={12}
          style={{ margin: '0 auto' }}
          id="schedule-copy"
        >
          <Typography variant="h2" id="info-header">
            {language.week12_tbox_header}
          </Typography>
          <br />
          <br />
          <Typography style={{ color: '#000', textAlign: "left" }}>
            {language.week12_tbox_text}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ height: '52px' }} />

      <div id="animate-fade-in">
        <Grid container id="icon-container">
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon1} alt={icon1} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i1_title}
                body={language.week12_i1_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon2} alt={icon2} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i2_title}
                body={language.week12_i2_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon3} alt={icon3} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
            {language.utm_code === "us_spa" ? (
                <AccordianItalic
                  title={language.week12_i3_title}
                  body1={language.week12_i3_body1}
                  bodyItalic={language.week12_i3_body_italic}
                  body2={language.week12_i3_body2}
                />
              ) : (
                <Accordian
                  title={language.week12_i3_title}
                  body={language.week12_i3_details}
                />
              )}
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon4} alt={icon4} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i4_title}
                body={language.week12_i4_details}
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ height: '52px' }} />
        <Grid container id="icon-container">
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon5} alt={icon5} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i5_title}
                body={language.week12_i5_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon6} alt={icon6} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i6_title}
                body={language.week12_i6_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon7} alt={icon7} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i7_title}
                body={language.week12_i7_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon10} alt={icon10} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i8_title}
                body={language.week12_i8_details}
              />
            </div>
          </Grid>
        </Grid>

        <div style={{ height: '52px' }} />
        <Grid container id="icon-container">
          <Grid item md={3} sm={12} xs={12} id="icon-grid">
            <img src={icon11} alt={icon11} id="link-icon" />
            <div style={{ marginTop: '16px' }}>
              <Accordian
                title={language.week12_i9_title}
                body={language.week12_i9_details}
              />
            </div>
          </Grid>
          <Grid item md={3} sm={12} xs={12} id="icon-grid"></Grid>
          <Grid item md={6} sm={12} xs={12} className="text-plus-img-wrapper">
            <div className="text-plus-img">
              <img src={callOutImg} alt={callOutImg} />
              <h5 className="text-on-img">
                {language.week12_bbox_text}
              </h5>
            </div>
          </Grid>
        </Grid>
      </div>

      <div style={{ height: '52px' }} />
    </div>
  );
};

export default Week12More;
