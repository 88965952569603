import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Header from "./Header";
import visitSideImg from "../images/exams_tests_img.png";
import Box from "@material-ui/core/Box";
import { useOptimmizeContextController } from "../context";

const VisitsPage = ({ activeLink }) => {
  const [controller] = useOptimmizeContextController();
  const { language } = controller;

  useEffect(() => {
    document.title = language.visits_title;
  }, []);

  return (
    <div id="info-page">
      <Header white={true} weekly={true} activeLink={activeLink} />
      <div id="top-divider" />

      <Grid container id="info-container">
        <Grid
          item
          className="purple-border"
          md={12}
          xl={12}
          xs={12}
          style={{ margin: "0 auto" }}
          id="schedule-copy"
        >
          <Typography
            variant="h2"
            id="info-header"
            style={{
              fontFamily: ["POL"].includes(language.country_code)
                ? "Gothiks-Round"
                : "",
              letterSpacing: ["POL"].includes(language.country_code)
                ? ".1rem"
                : "",
            }}
          >
            {language.visits_header1}
          </Typography>
        </Grid>
      </Grid>

      <Grid container id="info-container">
        <Grid
          item
          md={7}
          xl={7}
          xs={12}
          style={{
            margin: "auto",
          }}
          id="info-copy"
          className="contact-pg-text"
        >
          <br />
          <div className="purple-box">
            <Typography style={{ color: "#000" }}>
              {language.visits_box_line1}
              <br />
              <br /> {language.visits_box_line2}
              <br />
              <br />
              {language.visits_box_line3}
              <br />
            </Typography>
          </div>
        </Grid>
        <Grid item md={5} xl={5} xs={12}>
          <div id="animate-right-in" className="contact-pg-side-img">
            <img src={visitSideImg} alt={visitSideImg} id="side-img-3" />
          </div>
        </Grid>
      </Grid>
      <div style={{ height: "52px" }} />

      <Box component="div" display={{ xs: "block", sm: "block", md: "none" }}>
        <Grid container>
          <Grid item xs={12} id="icon-grid">
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year1_m}
            </Typography>
            <button className="icon-button2">
              <Link to="/week0" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week0_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week12_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week24_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week36_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week48_m}</div>
              </Link>
            </button>
            <div style={{ height: "52px" }} />
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year2_m}
            </Typography>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week60_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week72_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week84_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week96_m}</div>
              </Link>
            </button>
            <div style={{ height: "52px" }} />
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year3_m}
            </Typography>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week108_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week120_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week132_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week144_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week156_m}</div>
              </Link>
            </button>
            <div style={{ height: "52px" }} />
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year4_m}
            </Typography>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week168_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week180_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week192_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week204_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/week216" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week216_m}</div>
              </Link>
            </button>
            <button className="icon-button2">
              <Link to="/followup" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">
                  {language.visits_follow_up_m}
                </div>
              </Link>
            </button>
          </Grid>
        </Grid>
      </Box>

      <Box
        id="animate-fade-in"
        component="div"
        display={{ xs: "none", sm: "none", md: "block" }}
      >
        <Grid container id="icon-container">
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year1}
            </Typography>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year2}
            </Typography>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year3}
            </Typography>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <Typography
              style={{
                fontFamily: "Abingdon Regular",
                fontSize: "2rem",
                textDecoration: "underline",
              }}
            >
              {language.visits_year4}
            </Typography>
          </Grid>
        </Grid>

        <Grid container id="icon-container">
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week0" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week0}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week60}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week108}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week168}</div>
              </Link>
            </button>
          </Grid>
        </Grid>

        <Grid container id="icon-container">
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week12}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week72}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week120}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week180}</div>
              </Link>
            </button>
          </Grid>
        </Grid>

        <Grid container id="icon-container">
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week24}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week84}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week132}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week192}</div>
              </Link>
            </button>
          </Grid>

          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week36}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week96}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week144}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week204}</div>
              </Link>
            </button>
          </Grid>

          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week24more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week48}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid"></Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week12more" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week156}</div>
              </Link>
            </button>
          </Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/week216" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_week216}</div>
              </Link>
            </button>
          </Grid>

          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid"></Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid"></Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid"></Grid>
          <Grid item md={3} xl={3} sm={12} xs={12} id="icon-grid">
            <button className="icon-button2">
              <Link to="/followup" style={{ textDecoration: "none" }}>
                <div id="schedule-button-text">{language.visits_follow_up}</div>
              </Link>
            </button>
          </Grid>
        </Grid>
      </Box>

      <div style={{ height: "52px" }} />
    </div>
  );
};

export default VisitsPage;
